class Chart {
	constructor(element) {
		this.element = element;
		return this;
	}

	prepare() {

		const that = this;

		$(".portlet-body").on("click", "table.chart-table td", function(e) { that.clickOnCell(e); });

		AmCharts.addInitHandler(function(chart) {
			that.initTable(chart);
		}, ["serial"]);

		if (typeof(AmCharts) !== "undefined" ){
			AmCharts.translations.export.ru = {
				"fallback.save.text": "CTRL + C - скопировать в буфер обмена.",
				"fallback.save.image": "Правая кнопка мыши -> Сохранить как... чтобы сохранить изображение.",

				"capturing.delayed.menu.label": "{{duration}}",
				"capturing.delayed.menu.title": "Нажмите для отмены",

				"menu.label.print": "Печать",
				"menu.label.undo": "Отменить",
				"menu.label.redo": "Повторить",
				"menu.label.cancel": "Отмена",

				"menu.label.save.image": "Скачать как ...",
				"menu.label.save.data": "Сохранить как ...",

				"menu.label.draw": "Аннотации ...",
				"menu.label.draw.change": "Изменить ...",
				"menu.label.draw.add": "Добавить ...",
				"menu.label.draw.shapes": "Фигуры ...",
				"menu.label.draw.colors": "Цвет ...",
				"menu.label.draw.widths": "Размер ...",
				"menu.label.draw.opacities": "Прозрачность ...",
				"menu.label.draw.text": "Текст",

				"menu.label.draw.modes": "Режим ...",
				"menu.label.draw.modes.pencil": "Карандаш",
				"menu.label.draw.modes.line": "Линия",
				"menu.label.draw.modes.arrow": "Стрелка",

				"label.saved.from": "Сохранено из: "
			};
		}
	};

	initTable(chart) {
		// const data = chart.dataProvider;
		const chartDiv = $(chart.chartDiv.closest(".chart"));
		const table = $("<table class=\"table table-striped table-bordered table-hover chart-table\"></table>");
		chartDiv.parent().append(table);

		let tr, td;
		for (let x = 0; x < chart.dataProvider.length; x++) {
			// first row
			if (x === 0) {
				tr = $("<tr></tr>");
				table.append(tr);
				td = $("<th>" + chart.categoryAxis.title + "</th>");
				tr.append(td);
				for (let i = 0; i < chart.graphs.length; i++) {
					td = $('<th data-valuefield="' + chart.graphs[i].valueField + '">' + chart.graphs[i].title + '</th>');
					tr.append(td);
				}
			}

			// add rows
			tr = $("<tr></tr>").appendTo(table);
			td = $('<td class="row-title">' + chart.dataProvider[x][chart.categoryField] + '</td>').appendTo(tr);
			for (let r = 0; r < chart.graphs.length; r++) {
				td = $('<td data-link="' + chart.graphs[r].valueField + '" data-valuefield="' + chart.graphs[r].valueField + '">' + chart.dataProvider[x][chart.graphs[r].valueField] + '</td>').appendTo(tr);
			}
		}
	};

	clickOnCell(e) {
		const cell = $(e.target);
		const date = cell.parent().children(".row-title");

		window.open(cell.data("link") + "&" + date.text());
	};

	onAJAXSuccess(data) {
		const that = this;
		if (data.dataProvider != null) {
			this.dynamicResize(data);

			if (data.type === "serial") {

				data.dataDateFormat = "DD.MM.YYYY";

				for (let i = 0; i < Object.keys(data.dataProvider[0]).length - 1; i++) {
					data.graphs[i] = {
						"id": [i],
						"bullet": data.graphs[i].bullet,
						"balloonText": "[[title]] [[category]]:[[value]]",
						"fillColors": data.graphs[i].color,
						"lineColor": data.graphs[i].color,
						"fillAlphas": data.graphs[i].fillAlphas,
						"labelText": data.graphs[i].labelText,
						"title": data.graphs[i].title,
						"type": data.graphs[i].type,
						"valueField": data.graphs[i].tableID,
						"link": data.graphs[i].link
					};

				}
				data.listeners = [{
					"event": "clickGraphItem",
					"method": function(e) { that.clickOnGraph(e); }
				},
					{
						"event": "drawn",
						"method": function(e){ that.whenDrawnGraph(e); }
					}];
			}

			data.language = "ru";
			data.startDuration = 1;

			new AmCharts.makeChart(this.element[0].id, data);
		} else {
			Toastr.warning("Нет данных");
		}
	};

///////////// Chart Events /////////////

	clickOnGraph(e) {

		const cell = $("td:contains('" + e.item.dataContext.date + "')").nextAll("td[data-valuefield='" + e.graph.valueField + "']");
		cell.pulsate({
			color: e.graph.fillColors,
			reach: 50,
			repeat: 3,
			speed: 250,
			glow: true
		});
		$("html, body").animate({
			scrollTop: cell.offset().top - ($(window).height() / 2)
		}, 250);
	};

	whenDrawnGraph(){
		const chart = this.getChart(this.element[0].id);
		const tdth = $("td, th");
		tdth.show();
		for (let graph in chart.graphs.length){
			if (graph.hidden === true){
				tdth.each(function(){
					if ($(this).data('valuefield')=== graph.valueField){
						$(this).hide();
					}
				});
			}
		}
	};

///////////// Chart Functions /////////////

	getChart() {
		const allCharts = AmCharts.charts;
		for (let i = 0; i < allCharts.length; i++) {
			if (this.element[0].id === allCharts[i].div.id) {
				return allCharts[i];
			}
		}
	};

	clearChart() {
		const chart = this.getChart(this.element[0].id);
		if (chart !== undefined) {
			const id = (chart.div.id);
			// const cell = $("#" + id);

			chart.clear();
			this.element.parent().children("table").remove();
		}
	};

	dynamicResize(data) {
		console.log(this);
		if (data.type === "serial") {
			if (data.rotate === true) {
				this.element.css({ height: ((data.dataProvider.length * 25) + 160) + "px" });
			} else {
				this.element.css({ height: "600px" });
			}
		}
		if (data.type === "pie") { this.element.css({ height: "600px" }); }
		if (data.type === "gantt") { this.element.css({ height: "300px" }); }
	};

	clickSubmit(){

		let params = {};
		const form = $('form.filters'); // TODO: define form more accurately
		if(form.length) {
			$('form.filters .filtergroup').each(function() {
				if($(this).find('input.date-range').length) {
					if($(this).find('input.date-range.start').val() || $(this).find('input.date-range.end').val()) {
						params[$(this).find('input.date-range').attr('name')] = $(this).find('input.date-range.start').val() + ' - ' + $(this).find('input.date-range.end').val();
					}
				} else if($(this).find('input:checked').length) {
					params[$(this).find('input:checked').attr('name')] = $(this).find('input:checked').val();
				} else if($(this).find('.select2').length && $(this).find('.select2').val()) {
					params[$(this).find('.select2').attr('name')] = $(this).find('.select2').val();
				} else if($(this).find('input[type!=checkbox][type!=radio][type!=search]:not(.daterange)').length) {
					params[$(this).find('input[type!=checkbox][type!=radio][type!=search]:not(.daterange)').attr('name')] = $(this).find('input[type!=checkbox][type!=radio][type!=search]:not(.daterange)').val();
				}
			});
			if(form.find('input[name=search_context]').length && form.find('input[name=search_context]').val()) {
				params.search_context = form.find('input[name=search_context]').val();
			}
			if(form.find('input[name=itid]').length && form.find('input[name=itid]').val()) {
				params.itid = form.find('input[name=itid]').val();
			}
			if(form.find('input[name=wmode]').length && form.find('input[name=wmode]').val()) {
				params.wmode = form.find('input[name=wmode]').val();
			}
		} else {
			Toastr.debug('No form?', 'warning');
		}


		App.blockUI({boxed: true, target: $("portlet-body"), message: "Загрузка..."});
		if(ajax !== undefined && ajax) {
			ajax.abort();
		}

		let that = this;

		ajax = $.ajax({
			type: "post",
			data: $.param(params),
			success: function(data) {
				// that.id = data.id; // Take id from JSON <...>
				// if (typeof (data.id) === "undefined"){that.id = "chart0"}; // <...> if not then
				that.clearChart();
				that.onAJAXSuccess(data);
			},
			error: function(data) {
				if (data.statusText === 'abort') {
					return;
				}
				App.unblockUI(that.tparent);
				SWAL.show({message: 'Ошибка запроса'}, 'error');
			}
		});
	};
}

$.fn.chart = function() {
	return new Chart(this);
};

/*
function Chart(element) {
	this.element = element;
    return this;
}
*/


// $.fn.chart = function() {
//     return new Chart(this);
// };

///////////// Table generation and control /////////////
/*

Chart.prototype.prepare = function() {

	const that = this;

	$(".portlet-body").on("click", "table.chart-table td", function(e) { that.clickOnCell(e); });

	AmCharts.addInitHandler(function(chart) {
		that.initTable(chart);
	}, ["serial"]);

	if (typeof(AmCharts) !== "undefined" ){
		AmCharts.translations.export.ru = {
			"fallback.save.text": "CTRL + C - скопировать в буфер обмена.",
			"fallback.save.image": "Правая кнопка мыши -> Сохранить как... чтобы сохранить изображение.",

			"capturing.delayed.menu.label": "{{duration}}",
			"capturing.delayed.menu.title": "Нажмите для отмены",

			"menu.label.print": "Печать",
			"menu.label.undo": "Отменить",
			"menu.label.redo": "Повторить",
			"menu.label.cancel": "Отмена",

			"menu.label.save.image": "Скачать как ...",
			"menu.label.save.data": "Сохранить как ...",

			"menu.label.draw": "Аннотации ...",
			"menu.label.draw.change": "Изменить ...",
			"menu.label.draw.add": "Добавить ...",
			"menu.label.draw.shapes": "Фигуры ...",
			"menu.label.draw.colors": "Цвет ...",
			"menu.label.draw.widths": "Размер ...",
			"menu.label.draw.opacities": "Прозрачность ...",
			"menu.label.draw.text": "Текст",

			"menu.label.draw.modes": "Режим ...",
			"menu.label.draw.modes.pencil": "Карандаш",
			"menu.label.draw.modes.line": "Линия",
			"menu.label.draw.modes.arrow": "Стрелка",

			"label.saved.from": "Сохранено из: "
		};
	}
};

Chart.prototype.initTable = function(chart) {
	// const data = chart.dataProvider;

	const chartDiv = $(chart.chartDiv.closest(".chart"));
	const table = $("<table class=\"table table-striped table-bordered table-hover chart-table\"></table>");
	chartDiv.parent().append(table);

	let tr, td;
	for (let x = 0; x < chart.dataProvider.length; x++) {
		// first row
		if (x === 0) {
			tr = $("<tr></tr>");
			table.append(tr);
			td = $("<th>" + chart.categoryAxis.title + "</th>");
			tr.append(td);
			for (let i = 0; i < chart.graphs.length; i++) {
				td = $('<th data-valuefield="' + chart.graphs[i].valueField + '">' + chart.graphs[i].title + '</th>');
				tr.append(td);
			}
		}

		// add rows
		tr = $("<tr></tr>").appendTo(table);
		td = $('<td class="row-title">' + chart.dataProvider[x][chart.categoryField] + '</td>').appendTo(tr);
		for (let r = 0; r < chart.graphs.length; r++) {
			td = $('<td data-link="' + chart.graphs[r].valueField + '" data-valuefield="' + chart.graphs[r].valueField + '">' + chart.dataProvider[x][chart.graphs[r].valueField] + '</td>').appendTo(tr);
		}
	}
};

Chart.prototype.clickOnCell = function (e) {
	const cell = $(e.target);
	const date = cell.parent().children(".row-title");

	window.open(cell.data("link") + "&" + date.text());
};

Chart.prototype.onAJAXSuccess = function(data) {
	const that = this;
	if (data.dataProvider != null) {
        this.dynamicResize(data);

        if (data.type === "serial") {

            data.dataDateFormat = "DD.MM.YYYY";

            for (let i = 0; i < Object.keys(data.dataProvider[0]).length - 1; i++) {
	                data.graphs[i] = {
	                    "id": [i],
	                    "bullet": data.graphs[i].bullet,
	                    "balloonText": "[[title]] [[category]]:[[value]]",
	                    "fillColors": data.graphs[i].color,
	                    "lineColor": data.graphs[i].color,
	                    "fillAlphas": data.graphs[i].fillAlphas,
                        "labelText": data.graphs[i].labelText,
	                    "title": data.graphs[i].title,
	                    "type": data.graphs[i].type,
	                    "valueField": data.graphs[i].tableID,
	                    "link": data.graphs[i].link
	                };

	            }
            data.listeners = [{
			        "event": "clickGraphItem",
			        "method": function(e) { that.clickOnGraph(e); }
			    },
			    {
			        "event": "drawn",
			        "method": function(e){ that.whenDrawnGraph(e); }
		    }];
	        }

        data.language = "ru";
        data.startDuration = 1;

        new AmCharts.makeChart(this.element[0].id, data);
    } else {
        Toastr.warning("Нет данных");
    }
};

///////////// Chart Events /////////////

Chart.prototype.clickOnGraph = function (e) {

	const cell = $("td:contains('" + e.item.dataContext.date + "')").nextAll("td[data-valuefield='" + e.graph.valueField + "']");
	cell.pulsate({
        color: e.graph.fillColors,
        reach: 50,
        repeat: 3,
        speed: 250,
        glow: true
    });
    $("html, body").animate({
        scrollTop: cell.offset().top - ($(window).height() / 2)
    }, 250);
};

Chart.prototype.whenDrawnGraph = function (){
	const chart = this.getChart(this.element[0].id);
	const tdth = $("td, th");
	tdth.show();
    for (let graph of chart.graphs.length){
        if (graph.hidden === true){
            tdth.each(function(){
                if ($(this).data('valuefield')=== graph.valueField){
                    $(this).hide();
                }
            });
        }
    }
};

///////////// Chart Functions /////////////

Chart.prototype.getChart = function() {
	const allCharts = AmCharts.charts;
	for (let i = 0; i < allCharts.length; i++) {
        if (this.element[0].id === allCharts[i].div.id) {
            return allCharts[i];
        }
    }
};

Chart.prototype.clearChart = function() {
	const chart = this.getChart(this.element[0].id);
	if (chart !== undefined) {
	    const id = (chart.div.id);
	    // const cell = $("#" + id);

	    chart.clear();
        this.element.parent().children("table").remove();
    }
};

Chart.prototype.dynamicResize = function(data) {
    console.log(this);
    if (data.type === "serial") {
        if (data.rotate === true) {
            this.element.css({ height: ((data.dataProvider.length * 25) + 160) + "px" });
        } else {
            this.element.css({ height: "600px" });
        }
    }
    if (data.type === "pie") { this.element.css({ height: "600px" }); }
    if (data.type === "gantt") { this.element.css({ height: "300px" }); }
};

*/
